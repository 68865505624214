import React from 'react'
import ReactDOM from 'react-dom'
import ReactMarkDown from 'react-markdown'
import {Link, graphql} from 'gatsby'
import Layout from '../components/layout'
import Author from '../components/author'
import tw from "../images/icon_twitter.svg";
import fb from "../images/icon_facebook.svg";
import Img from "gatsby-image";
import ArticleList from "../components/article-list";
import {deployPath, getExerpt} from "../environment";
import CodeBlock from "../components/codeblock";
import SEO from "../components/seo";

const BlogEnTemplate = ({data}) => (
    <Layout lang="en" pagePath={data.strapiBlog.slug} category={data.strapiBlog.category.slug}>
      <SEO title={data.strapiBlog.title_en} keywords={[`Pikon log`, `Pikon Inc.`, `Tech Blog`]} description={getExerpt(data.strapiBlog.content_en)} lang="en"/>
      {(()=>{
        if(!data.strapiBlog.isTranslated){
          return (
              <article className="article">
                <section className="article__article-body">
                  <p style={{textAlign:"center"}}>This article hasn't translated in English yet.<br/>Please go back to the Japanese page.</p>
                </section>
              </article>
                )
        }else{
          return (<article className="article">
            <header className="article__article-header">
              <time className="article__article-date">{data.strapiBlog.publishDate}</time>
              <Link to={`/en/category/${data.strapiBlog.category.slug}`} className="article__article-category">{data.strapiBlog.category.name_en}</Link>
              <h1 className="article__article-title">{data.strapiBlog.title_en}</h1>
              {((author)=>{
                if(author) {
                  return (
                      <aside className="article__author--simple author">
                        <Author author={author} lang="en" type="simple"/>
                      </aside>
                  )
                }
              })(data.strapiAuthor)}
            </header>

            <section className="article__article-featured-image">
              {(image => {
                return image == null ? "" : (
                    <Img fluid={image.childImageSharp.fluid}/>)
              })(data.strapiBlog.featuredImage)}
            </section>

            <section className="article__article-body">
              <ReactMarkDown
                  escapeHtml={false}
                  source={data.strapiBlog.content_en}
                  renderers={{ code: CodeBlock }}
              />
            </section>

            <footer className="article__article-footer">
              <div className="article__article-link-box">
                <ul className="article__tags tags">
                  {data.strapiBlog.tags.map(document => (
                      <li className="tags__tag-item" key={document.id}>
                        <Link to={`/en/tag/${document.slug}`}>{document.name_en}</Link>
                      </li>
                  ))}
                </ul>
                <ul className="article__article-share share">
                  <li className="share__share-item">
                    <a href={`http://twitter.com/share?text=${data.strapiBlog.title_en}&amp;url=${deployPath + "/en/" + data.strapiBlog.slug}`} rel="nofollow" target="_blank">
                      <img src={tw} alt="" width="28" height="28"/>
                    </a>
                  </li>
                  <li className="share__share-item">
                    <a href={`http://www.facebook.com/share.php?u=${deployPath + "/en/" + data.strapiBlog.slug}`} target="_blank">
                      <img src={fb} alt="" width="28" height="28"/>
                    </a>
                  </li>
                </ul>
              </div>
              {((author)=>{
                if(author) {
                  return (
                      <aside className="article__author author">
                        <Author author={author} lang="en"/>
                      </aside>
                  )
                }
              })(data.strapiAuthor)}
            </footer>
          </article>)
        }
      })()}

      {(()=>{
        if(data.allStrapiBlog.edges.length !== 0){
          return (<ArticleList blogs={data.allStrapiBlog.edges} type="enumeration" lang="en" heading="Related Posts"/>)
        }
      })()}
    </Layout>
)

export default BlogEnTemplate

export const query = graphql`
  query BlogEnTemplate($id: String!, $authorId: String!, $categorySlug: String!)  {
    strapiBlog(id:{eq:$id}){
      featuredImage {
        id
        childImageSharp {
          id
           fluid(maxWidth: 1000) {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
      category{
        name
        name_en
        slug
      }
      tags{
        id
        name
        name_en
        slug
      }
      title
      title_en
      slug
      isTranslated
      content
      content_en
      publishDate(formatString: "MMM DD, YYYY")
    }
    strapiAuthor(strapiId:{eq:$authorId}){
      id
      name
      name_en
      profile
      profile_en
      position
      position_en
      photo {
        id
        childImageSharp{
          fixed(width: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    allStrapiBlog(
      filter:{
        category:{
          slug:{
            eq:$categorySlug
          }
        },
        isTranslated:{
          eq:true
        },
        status:{
          eq:true
        },
        id:{
          ne:$id
        }
      },
      sort:{
        fields: [publishDate, id]
  	    order: [DESC, DESC]
      }
    ){
      edges{
        node{
          id
          title
          title_en
          publishDate(formatString: "YYYY.MM.DD")
          strapiId
          slug
          isTranslated
          category {
            id
            name
            name_en
            slug
          }
          featuredImage {
            id
            childImageSharp{
              fixed(width: 160,height: 120) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`